import React, { useContext } from "react";
import App from "../../components/App";
import GlobalContext from "../../context/GlobalContext";

const Account = () => {
  const { session } = useContext(GlobalContext);
  if (!session) return null;
  return (
    <App>
      <h4>Account</h4>
      <form>
        <div className="form-group mt-6">
          <label className="font-weight-bold" for="name">
            Name
          </label>
          <input
            type="text"
            class="form-control"
            id="name"
            aria-describedby="name"
            placeholder="Name"
            value={`${session.firstName} ${session.lastName}`}
          />
        </div>
        <div className="form-group">
          <label className="font-weight-bold" for="email">
            Email
          </label>
          <input
            type="text"
            class="form-control"
            id="email"
            aria-describedby="email"
            placeholder="Email"
            value={session.email}
          />
        </div>
      </form>
    </App>
  );
};

export default Account;
