import React, { useContext } from "react";
import { Link, navigate } from "gatsby";

import PageWrapper from "./PageWrapper";
import GlobalContext from "../context/GlobalContext";
import { windowGlobal } from "../config";

const App = ({ children }) => {
  const { session, setSession } = useContext(GlobalContext);
  const logout = () => {
    windowGlobal?.localStorage?.removeItem("@modstop/session");
    setSession(null);
    navigate("/");
  };

  if (!session && windowGlobal) {
    navigate("/");
    return null;
  }

  if (!session) return null

  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <a
                className="nav-link mt-4"
                role="button"
                onClick={logout}
                // href={`${name}`}
                // rel="noopener noreferrer"
              >
                Logout
              </a>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <div className="py-20 pt-md-26 pt-lg-28">
          <div className="container">
            <h3 className="mb-10">Welcome {session.firstName} :)</h3>
            <div className="row">
              <div className="col-3">
                <div className="list-group">
                  <Link
                    to="/app/api"
                    activeClassName="list-group-item list-group-item-action active"
                    className="list-group-item list-group-item-action"
                  >
                    API
                  </Link>
                  {/* <Link
                    to="/app/analytics"
                    activeClassName="list-group-item list-group-item-action active"
                    className="list-group-item list-group-item-action"
                  >
                    Analytics
                  </Link> */}
                  <Link
                    to="/app/usage"
                    activeClassName="list-group-item list-group-item-action active"
                    className="list-group-item list-group-item-action"
                  >
                    Usage
                  </Link>
                  <Link
                    to="/app/integrate"
                    activeClassName="list-group-item list-group-item-action active"
                    className="list-group-item list-group-item-action"
                  >
                    Integrate
                  </Link>
                  <Link
                    to="/app/billing"
                    activeClassName="list-group-item list-group-item-action active"
                    className="list-group-item list-group-item-action"
                  >
                    Billing
                  </Link>
                  <Link
                    to="/app/account"
                    activeClassName="list-group-item list-group-item-action active"
                    className="list-group-item list-group-item-action"
                  >
                    Account
                  </Link>
                </div>
              </div>
              <div className="col">{children}</div>
            </div>
          </div>
        </div>
        {/* <Hero />
        <Pricing />
        <Faq /> */}
      </PageWrapper>
    </>
  );
};

export default App;
